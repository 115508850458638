import React from "react";
import "../css/Packages.css";
import { useNavigate } from "react-router-dom";

const packagesData = [
  {
    id: 1,
    title: "Regular",
    details: [
      {
        time: "11:00 AM - 9:00 PM",
        priceDetails: [
          "1st Child — $30/3 Hours",
          "2nd Child — $25/3 Hours",
          "3rd Child — $20/3 Hours",
          "Membership — $130-$150",
        ],
      },
    ],
  },
  {
    id: 2,
    title: "Adventure",
    price: "$1650",
    description: "Adventure",
    detailsPage: "/adventure",
  },
  {
    id: 3,
    title: "Ultimate",
    price: "$2150",
    description: "Ultimate",
    detailsPage: "/ultimate",
  },
  {
    id: 4,
    title: "VIP",
    price: "$2750",
    description: "VIP",
    detailsPage: "/vip",
  },
  {
    id: 5,
    title: "VIP",
    price: "$3650",
    description: "VIP",
    detailsPage: "/vip",
  },
];

const Packages = () => {
  const navigate = useNavigate();

  const handlePackageClick = (detailsPage) => {
    // Navigate to the package details page and scroll to top
    navigate("/packagesdetail");
    window.scrollTo(0, 0); // Scroll to top
  };

  return (
    <div className="packages-section">
      {/* Background text */}
      <div className="packages-background-text">PACKAGES</div>

      {/* Main card container */}
      <div className="package-card-container">
        <div className="package-card">
          {/* Regular Package */}
          <div className="regular-package">
            <h3>{packagesData[0].title}</h3>
            <div className="package-time">
              <p>{packagesData[0].details[0].time}</p>
              <ul>
                {packagesData[0].details[0].priceDetails.map(
                  (detail, index) => (
                    <li key={index}>{detail}</li>
                  )
                )}
              </ul>
            </div>
          </div>

          {/* Dashed line between regular and other packages */}
          <div className="package-divider"></div>

          {/* Other Packages */}
          {packagesData.slice(1).map((pkg, index) => (
            <React.Fragment key={pkg.id}>
              <div className="package-box">
                <h3>{pkg.price}</h3>
                <p>{pkg.description}</p>
                <button
                  className="details-button"
                  onClick={() => handlePackageClick(pkg.detailsPage)}
                >
                  Details
                </button>
              </div>

              {/* Dashed line between other packages, except the last one */}
              {index < packagesData.slice(1).length - 1 && (
                <div className="package-divider"></div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Packages;
