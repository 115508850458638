// import React, { useState } from "react";
// import { useForm, ValidationError } from "@formspree/react";
// import "../css/BookNow.css"; // Import the styles for the modal and button

// const BookNow = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [state, handleSubmit] = useForm("xdknvawd"); // Replace with your Formspree project ID

//   // Toggle the modal
//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   return (
//     <>
//       {/* Book Now Button */}
//       <button className="book-now-btn" onClick={toggleModal}>
//         Book Now
//       </button>

//       {/* Modal */}
//       {isModalOpen && (
//         <div className="modal">
//           <div className="modal-content">
//             <span className="close" onClick={toggleModal}>
//               &times;
//             </span>
//             <h2 className="center-heading">Book Your Event</h2>
//             <form onSubmit={handleSubmit}>
//               {/* Form Content */}
//               <div className="form-group row">
//                 <div className="form-group-half">
//                   <label htmlFor="firstName">First Name:</label>
//                   <input type="text" id="firstName" name="firstName" required />
//                   <ValidationError
//                     prefix="First Name"
//                     field="firstName"
//                     errors={state.errors}
//                   />
//                 </div>
//                 <div className="form-group-half">
//                   <label htmlFor="lastName">Last Name:</label>
//                   <input type="text" id="lastName" name="lastName" required />
//                   <ValidationError
//                     prefix="Last Name"
//                     field="lastName"
//                     errors={state.errors}
//                   />
//                 </div>
//               </div>
//               <div className="form-group row">
//                 <div className="form-group-half">
//                   <label htmlFor="phone">Phone Number:</label>
//                   <input type="tel" id="phone" name="phone" required />
//                   <ValidationError
//                     prefix="Phone"
//                     field="phone"
//                     errors={state.errors}
//                   />
//                 </div>
//                 <div className="form-group-half">
//                   <label htmlFor="email">Email:</label>
//                   <input type="email" id="email" name="email" required />
//                   <ValidationError
//                     prefix="Email"
//                     field="email"
//                     errors={state.errors}
//                   />
//                 </div>
//               </div>
//               <div className="form-group row">
//                 <div className="form-group-half">
//                   <label htmlFor="date">Date:</label>
//                   <input type="date" id="date" name="date" required />
//                   <ValidationError
//                     prefix="Date"
//                     field="date"
//                     errors={state.errors}
//                   />
//                 </div>
//                 <div className="form-group-half">
//                   <label htmlFor="time">Time:</label>
//                   <input type="time" id="time" name="time" required />
//                   <ValidationError
//                     prefix="Time"
//                     field="time"
//                     errors={state.errors}
//                   />
//                 </div>
//               </div>
//               <div className="form-group">
//                 <label htmlFor="kids">Number of Kids:</label>
//                 <input type="number" id="kids" name="kids" required />
//                 <ValidationError
//                   prefix="Kids"
//                   field="kids"
//                   errors={state.errors}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="guests">Number of Guests:</label>
//                 <input type="number" id="guests" name="guests" required />
//                 <ValidationError
//                   prefix="Guests"
//                   field="guests"
//                   errors={state.errors}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="message">Message:</label>
//                 <textarea id="message" name="message" required></textarea>
//                 <ValidationError
//                   prefix="Message"
//                   field="message"
//                   errors={state.errors}
//                 />
//               </div>
//               <button type="submit" disabled={state.submitting}>
//                 Submit Booking
//               </button>
//             </form>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default BookNow;
import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/BookNow.css"; // Import the styles for the button

const BookNow = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/book"); // Navigate to the booking page
  };

  return (
    <button className="book-now-btn" onClick={handleNavigation}>
      Book Now
    </button>
  );
};

export default BookNow;
