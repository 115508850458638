import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../css/LandingPage.css";
import HomePage from "../pages/HomePage"; // Your homepage component

const LandingPageWithTransition = () => {
  const pageRef = useRef(null);
  const bubblesContainerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const container = bubblesContainerRef.current;
    const page = pageRef.current;
    const bubbles = [];

    // Define min and max sizes for random bubbles
    const minBubbleSize = 80;
    const maxBubbleSize = 200;

    const numBubbles = 30; // Number of bubbles

    for (let i = 0; i < numBubbles; i++) {
      const bubble = document.createElement("div");
      bubble.classList.add("bubble");

      // Set random size for each bubble
      const randomSize =
        Math.floor(Math.random() * (maxBubbleSize - minBubbleSize + 1)) +
        minBubbleSize;
      bubble.style.width = `${randomSize}px`;
      bubble.style.height = `${randomSize}px`;

      // Set random initial position at the bottom
      bubble.style.left = `${Math.random() * window.innerWidth}px`;
      bubble.style.bottom = `-${randomSize}px`; // Start from the bottom

      // Randomize the animation duration for more variation
      const randomDuration = Math.random() * 2 + 3; // Between 5-10 seconds
      bubble.style.animationDuration = `${randomDuration}s`;

      container.appendChild(bubble);
      bubbles.push(bubble);
    }

    // Add the class for the "page-turn" animation
    setTimeout(() => {
      page.classList.add("turn-page");
    }, 5000); // Start turning the page after 3 seconds

    // Redirect to the homepage after the page turn animation completes
    setTimeout(() => {
      navigate("/home"); // After animation, fully navigate to the /home route
    }, 10000); // Redirect after 10 seconds

    return () => {
      bubbles.forEach((bubble) => container.removeChild(bubble)); // Clean up bubbles
    };
  }, [navigate]);

  return (
    <div className="wrapper">
      {/* Landing Page */}
      <div className="landing-page" ref={pageRef}>
        <div className="bubbles" ref={bubblesContainerRef}></div>
      </div>

      {/* Homepage behind the landing page */}
      <div className="homepage-container">
        <HomePage />
      </div>
    </div>
  );
};

export default LandingPageWithTransition;
