import React from "react";
import "../css/PackagesDetail.css";
import Navbar from "./Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"; // Importing checkmark icon

const packagesData = [
  {
    id: 2,
    title: "Fun Play Ultimate Adventure",
    price: "$1,650",
    serviceFee: true,
    details: [
      { label: "Party Time", value: "2 Hours" },
      { label: "Kids", value: "(Each Additional Kid $15) up to 20" },
      { label: "Adult", value: "(Each Additional Adult $15) up to 25" },
      { label: "Helpers", value: "2-3" },
      { label: "Large 1 Topping Pizza", value: "4" },
      { label: "Organic Juice Boxes", value: "20" },
      { label: "Sodas", value: "For Adults" },
      { label: "Water Bottles", value: "For All Guests" },
      { label: "Non-Slip Socks", value: "For All Guests" },
    ],
    additionalInfo: [
      "Coffee served with creamer & sugar",
      "Plates, napkins, cups, flatware for adults",
      "Cake celebration (cutting, serving)",
      "Cake stands provided (clear)",
      "Groove to complimentary background music for extra fun and cheer!",
    ],
  },

  {
    id: 3,
    title: "Fun Play Ultimate Adventure",
    price: "$2,150",
    serviceFee: true,

    details: [
      { label: "Party Time", value: "2.5 Hours" },
      { label: "Kids", value: "(Each Additional Kid $18) up to 25" },
      { label: "Adult", value: "(Each Additional Adult $15) up to 30" },
      { label: "Helpers", value: "2-3" },
      { label: "Large 1 Topping Pizza", value: "5" },
      { label: "Organic Juice Boxes", value: "25" },
      { label: "Sodas", value: "For Adults" },
      { label: "Water Bottles", value: "For All Guests" },
      { label: "Non-Slip Socks", value: "For All Guests" },
    ],
    additionalInfo: [
      "Coffee served with creamer & sugar",
      "Themed plates napkins, and centerpieces for the kid's table",
      "Plates, napkins, cups, flatware for adults",
      "Cake celebration (cutting, serving)",
      "Cake plates & forks provided",
      "Cake stands are provided, with clear, white options and a variety of colors and designs to choose from",
      "Groove to complimentary background music for extra fun and cheer!",
    ],
  },
  {
    id: 4,
    title: "Fun Play Ultimate Adventure",
    price: "$2,750",
    serviceFee: true,

    details: [
      { label: "Party Time", value: "3 Hours" },
      { label: "Kids", value: "(Each Additional Kid $20) up to 30" },
      { label: "Adult", value: "(Each Additional Adult $15) up to 35" },
      { label: "Helpers", value: "2-3" },
      { label: "Large 1 Topping Pizza", value: "7" },
      { label: "Organic Juice Boxes", value: "Unlimited" },
      { label: "Sodas", value: "For Adults" },
      { label: "Water Bottles", value: "For All Guests" },
      { label: "Non-Slip Socks", value: "For All Guests" },
    ],
    additionalInfo: [
      "Coffee served with creamer & sugar",
      "Themed plates napkins, and centerpieces for the kid's table",
      "Plates, napkins, cups, flatware for adults",
      "Cake celebration (cutting, serving)",
      "Cake plates & forks provided",
      "Cake stands are provided, with clear, white options and a variety of colors and designs to choose from",
      "Goodie bags for all kids",
      "Goodie bag display stand provided",
      "Character for your party (1 hour)",
      "1 free return open play pass for the birthday child and their sibling(s)",
      "Groove to complimentary background music for extra fun and cheer!",
    ],
  },
  {
    id: 5,
    title: "Fun Play Ultimate Adventure",
    price: "$3,650",
    serviceFee: true,

    details: [
      { label: "Party Time", value: "4 Hours" },
      { label: "Kids", value: "(Each Additional Kid $20) up to 30" },
      { label: "Adult", value: "(Each Additional Adult $15) up to 35" },
      { label: "Helpers", value: "2-3" },
      { label: "Large 1 Topping Pizza", value: "8" },
      { label: "Organic Juice Boxes", value: "Unlimited" },
      { label: "Sodas", value: "For Adults" },
      { label: "Water Bottles", value: "For All Guests" },
      { label: "Non-Slip Socks", value: "For All Guests" },
    ],
    additionalInfo: [
      "Coffee served with creamer & sugar",
      "Themed plates napkins, and centerpieces for the kid's table",
      "Plates, napkins, cups, flatware for adults",
      "Cake celebration (cutting, serving)",
      "Cake plates & forks provided",
      "Cake stands are provided, with clear, white options and a variety of colors and designs to choose from",
      "Goodie bags for all kids",
      "Goodie bag display stand provided",
      "Character for your themed party (1 hour)",
      "Sparkly painting and glitter tattoos",
      "3 different desserts (45 pieces total)",
      "1 free return open play pass for the birthday child and their sibling(s)",
      "Groove to complimentary background music for extra fun and cheer!",
    ],
  },
  {
    id: 1,
    title: "Daily Pass",
    price: "$30",
    serviceFee: false,

    details: [
      { label: "Play Time", value: "3 Hours" },
      { label: "Monday-Sunday", value: "...................11Am-8Pm" },
      { label: "1st Child", value: "....................$30/3 hours" },
      { label: "2nd Child", value: "....................$25/3 hours" },
      { label: "3rd Child", value: "....................$20/3 hours" },
      {
        label: "",
        value:
          "Join our playground family and unlock endless fun whenever you want! With our membership, you can skip the booking stress and drop by for playtime whenever the space is free from events. Fun and excitement await you at every visit!",
      },
      //   {
      //     label: "",
      //     value:
      //       "Monthly Membership (Unlimited playtime  whenever the space is free from events)",
      //   },
      //   { label: "1st Child", value: "....................$150" },
      //   { label: "2nd Child", value: "....................$140" },
      //   { label: "3rd Child", value: "....................$130" },
    ],

    additionalInfo: [
      //   "Coffee served with creamer & sugar",
      //   "Themed plates napkins centerpieces for the kids' table",
      //   "Cake celebration (cutting, serving)",
    ],
  },
  {
    id: 6,
    title: "Add on",
    price: "From $300",
    serviceFee: false,

    details: [
      { label: "Extra 30 minutes of playtime", value: "$300" },
      { label: "Extra 1 hour of playtime", value: "$400" },
      { label: "Cotton Candy Station", value: " $200" },
      { label: "Sparkly Painting & Glitter Tattoos", value: " $150" },
      { label: "Fog Machine Magic", value: " $100" },
      { label: "Confetti Magic", value: " $150" },
      { label: "Themed Party Character", value: "Starting at $250" },
      { label: "360 Photo Booth Fun", value: " $150" },
      { label: "Goodie Bag Display Stand (one)", value: " $150" },
      { label: "Goodie Bag Display Stand (two)", value: " $250" },
    ],
    additionalInfo: [],
  },
  {
    id: 7,
    title: "Monthly Membership",
    price: "From $130",
    serviceFee: false,

    details: [
      {
        label: "Unlimited playtime  whenever the space is free from events",
        value: "",
      },
      { label: "1st Child", value: "....................$150" },
      { label: "2nd Child", value: "....................$140" },
      { label: "3rd Child", value: "....................$130" },
    ],
    additionalInfo: [],
  },

  {
    id: 8,
    title: "Rent Out Location For Commercial Use",
    price: "$800",
    details: [
      { label: "Duration", value: "1 Hour" },
      {
        label:
          "For photoshoots, commercials, and private play only. Birthdays are not permitted!",
        value: "",
      },
    ],
    additionalInfo: [],
  },
];

const PackagesDetail = () => {
  return (
    <div className="packages-detail-section">
      <Navbar /> {/* Navbar included here */}
      <h1 className="section-title">Birthday Packages</h1>
      <div className="packages-grid">
        {packagesData.map((pkg) => (
          <div key={pkg.id} className="package-detail-card">
            <h2 className="package-title">{pkg.title}</h2>
            <div className="price-box">
              <h3 className="package-price">{pkg.price}</h3>
              {pkg.serviceFee && (
                <p className="package-tax">+15% SERVICE FEE AND 9.5% TAX</p>
              )}{" "}
            </div>
            <ul className="package-details">
              {pkg.details.map((detail, index) => (
                <li key={index}>
                  {detail.label} <span>{detail.value}</span>
                </li>
              ))}
            </ul>
            {/* Display additional info if it exists */}
            {pkg.additionalInfo && pkg.additionalInfo.length > 0 && (
              <div className="additional-info">
                <ul>
                  {pkg.additionalInfo.map((info, index) => (
                    <li key={index}>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="check-icon"
                      />{" "}
                      {info}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
export default PackagesDetail;
