// import React, { useEffect, useRef } from "react";
// import "../css/HomePage.css";
// import Packages from "./Packages";
// import Navbar from "./Navbar"; // Import the Navbar component
// import Contact from "./Contact"; // Import the Contact component
// import Faq from "./FAQData"; // Import the FAQ component

// const HomePage = () => {
//   const videoRef = useRef(null);

//   // Scroll effect for the video
//   useEffect(() => {
//     const handleScroll = () => {
//       const video = videoRef.current;
//       const scrollPosition = window.scrollY;

//       if (video) {
//         video.style.transform = `translateY(${scrollPosition}px)`;
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   // Scroll to specific section if there's a hash in the URL
//   useEffect(() => {
//     const sectionId = window.location.hash.replace("#", "");
//     if (sectionId) {
//       const section = document.getElementById(sectionId);
//       if (section) {
//         section.scrollIntoView({ behavior: "smooth" });
//       }
//     }
//   }, []); // Run once on component load

//   return (
//     <div className="homepage">
//       <Navbar />

//       <section id="home" className="home-section">
//         <div className="video-container" ref={videoRef}>
//           <video className="full-video" autoPlay muted loop>
//             <source src="/socalvid.mp4" type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>
//       </section>
//       {/* Other Sections */}
//       <section id="gallery" className="section">
//         <h2>Gallery</h2>
//       </section>
//       <section id="packages" className="section">
//         <Packages />
//       </section>
//       <section id="waiver" className="section">
//         <Faq />
//       </section>
//       <section id="contact" className="section">
//         <Contact />
//       </section>
//     </div>
//   );
// };

// export default HomePage;
import React, { useEffect, useRef } from "react";
import "../css/HomePage.css";
import Packages from "./Packages";
import Navbar from "./Navbar"; // Import the Navbar component
import Contact from "./Contact"; // Import the Contact component
import Faq from "./FAQData"; // Import the FAQ component

const HomePage = () => {
  const videoRef = useRef(null);

  // Scroll effect for the video
  useEffect(() => {
    const handleScroll = () => {
      const video = videoRef.current;
      const scrollPosition = window.scrollY;

      if (video) {
        video.style.transform = `translateY(${scrollPosition}px)`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll to specific section if there's a hash in the URL
  useEffect(() => {
    const sectionId = window.location.hash.replace("#", "");
    if (sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []); // Run once on component load

  return (
    <div className="homepage">
      <Navbar />

      <section id="home" className="home-section relative">
        <div className="video-container absolute top-0 left-0 w-full h-full z-1">
          <video
            className="full-video w-full h-full object-cover"
            autoPlay
            muted
            loop
            ref={videoRef}
          >
            <source src="/socalvid.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </section>

      {/* Other Sections */}
      <section id="gallery" className="section bg-[#ffefdb] p-6 sm:p-10">
        <h2 className="text-xl sm:text-3xl">Gallery</h2>
      </section>

      <section id="packages" className="section bg-[#d1e7dd] p-6 sm:p-10">
        <Packages />
      </section>

      <section id="waiver" className="section bg-[#cfe2f3] p-6 sm:p-10">
        <Faq />
      </section>

      <section id="contact" className="section bg-[#fce5cd] p-6 sm:p-10">
        <Contact />
      </section>
    </div>
  );
};

export default HomePage;
