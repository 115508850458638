// src/App.js

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPageWithTransition from "./pages/LandingPageWithTransition"; // New transition component
import HomePage from "./pages/HomePage"; // Your homepage component
import PackagesDetail from "./pages/PackagesDetail";
import BookingForm from "./pages/BookingForm";

function App() {
  return (
    <Router>
      <Routes>
        {/* Landing page with transition effect */}
        <Route path="/" element={<LandingPageWithTransition />} />
        {/* Normal homepage with full functionality (scroll, etc.) */}
        <Route path="/home" element={<HomePage />} />
        <Route path="/packagesdetail" element={<PackagesDetail />} />
        <Route path="/book" element={<BookingForm />} />
        {/* Fallback route */}
        <Route path="*" element={<h1>404 - Page Not Found</h1>} />
      </Routes>
    </Router>
  );
}

export default App;
