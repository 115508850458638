import React, { useState, useEffect, useRef } from "react";
import { useForm, ValidationError } from "@formspree/react";
import "../css/Contact.css"; // Import the Contact-specific CSS

const Contact = () => {
  // Set up Formspree
  const [state, handleSubmit] = useForm("xdknvawd");
  const [thankYouMessage, setThankYouMessage] = useState("");

  // Create a reference to the form
  const formRef = useRef(null);

  // Check if form submission is successful and update the thank you message
  useEffect(() => {
    if (state.succeeded && formRef.current) {
      const formData = new FormData(formRef.current); // Use the form ref to access form data
      const firstName = formData.get("first-name");
      const lastName = formData.get("last-name");

      setThankYouMessage(
        `Thank you ${firstName} ${lastName}! Our team will connect with you within 24 hours.`
      );
    }
  }, [state.succeeded]); // Run this effect when the submission state changes

  return (
    <section id="contact" className="contact-section">
      <h1>Contact Us</h1>
      <div className="contact-content">
        {/* Form Box */}
        <div className="form-container">
          <h2>Let’s Chat About Your Event!</h2>
          {/* Display Thank You message if the form is successfully submitted */}
          {!thankYouMessage ? (
            <form
              ref={formRef}
              className="contact-form"
              onSubmit={handleSubmit}
            >
              <div className="form-group">
                <label htmlFor="first-name">First Name</label>
                <input type="text" id="first-name" name="first-name" required />
                <ValidationError
                  prefix="First Name"
                  field="first-name"
                  errors={state.errors}
                />
              </div>
              <div className="form-group">
                <label htmlFor="last-name">Last Name</label>
                <input type="text" id="last-name" name="last-name" required />
                <ValidationError
                  prefix="Last Name"
                  field="last-name"
                  errors={state.errors}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" required />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>
              <div className="form-group">
                <label htmlFor="subject">Subject</label>
                <input type="text" id="subject" name="subject" required />
                <ValidationError
                  prefix="Subject"
                  field="subject"
                  errors={state.errors}
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message" required></textarea>
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
              </div>
              <button
                type="submit"
                disabled={state.submitting || state.errors?.length > 0} // Safely access errors
              >
                Submit
              </button>
            </form>
          ) : (
            <div className="thank-you-message">
              <p>{thankYouMessage}</p>
            </div>
          )}
        </div>
        {/* Contact Details Box */}
        <div className="info-box">
          <h2>Let's Make Your Next Event a Royal Celebration!</h2>
          <div className="contact-details">
            <p>
              <i className="fas fa-phone icon-phone"></i>
              <a href="tel:+18188184270">818-818-4270</a>
            </p>
            <p>
              <i className="fas fa-map-marker-alt icon-location"></i>
              <a
                href="https://maps.google.com/?q=19444+Business+Center+Dr,+Northridge,+CA+91324"
                target="_blank"
                rel="noopener noreferrer"
              >
                19444 Business Center Dr, Northridge, CA 91324
              </a>
            </p>
            <p>
              <i className="fas fa-envelope icon-email"></i>
              <a href="mailto:kiddiekingdomplayground@gmail.com">
                kiddiekingdomplayground@gmail.com
              </a>{" "}
            </p>
            <p>
              <i className="fab fa-instagram icon-instagram"></i>
              <a
                href="https://www.instagram.com/kiddiekingdomplayground/"
                target="_blank"
                rel="noopener noreferrer"
              >
                kiddiekingdomplayground
              </a>
            </p>
          </div>
          <div className="map-container">
            <a
              href="https://www.google.com/maps?q=19444+Business+Ctr+Dr,+Northridge,+CA+91324"
              target="_blank"
              rel="noopener noreferrer"
            >
              <iframe
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA15VLVFgnvnBij74SjAlW0WV2nKHLrXsE&q=19444+Business+Ctr+Dr,+Northridge,+CA+91324"
                width="100%"
                height="100%"
                style={{ border: "0" }} // Correct
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Our Location Map"
              ></iframe>
            </a>
          </div>
        </div>
      </div>

      {/* Social Media Icons Below the Map */}
      <div className="social-media">
        <a
          href="https://facebook.com"
          className="facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook-f"></i>
        </a>
        <a
          href="https://www.instagram.com/kiddiekingdomplayground/"
          className="instagram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram"></i>
        </a>

        <a
          href="https://tiktok.com"
          className="tiktok"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-tiktok"></i>
        </a>
      </div>

      {/* Footer Section */}
      <footer className="footer">
        <p>&copy; 2024 Kiddie Kingdom. All rights reserved.</p>
      </footer>
    </section>
  );
};

export default Contact;
