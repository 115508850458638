import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { useForm, ValidationError } from "@formspree/react";
import "../css/BookNow.css"; // Import styles
import ribbon from "../media/ribbon.png";
import cloud_green from "../media/cloud_green.png";
import cloud_purple from "../media/cloud_purple.png";
import cloud_pink from "../media/cloud_pink.png";

const BookingForm = () => {
  const [state, handleSubmit] = useForm("xdknvawd"); // Replace with your Formspree project ID
  const [thankYouMessage, setThankYouMessage] = useState(null); // Manage thank-you message state
  //   const randomPosition = () => {
  //     const top = Math.random() * 80; // Random top between 0% and 80%
  //     const left = Math.random() * 90; // Random left between 0% and 90%
  //     return { top: `${top}%`, left: `${left}%` };
  //   };

  //   useEffect(() => {
  //     const clouds = document.querySelectorAll(".background-clouds");
  //     clouds.forEach((cloud) => {
  //       const { top, left } = randomPosition();
  //       cloud.style.top = top;
  //       cloud.style.left = left;
  //     });
  //   }, []);

  useEffect(() => {
    // If the form submission succeeded, display the thank-you message
    if (state.succeeded) {
      const formData = new FormData(document.querySelector("form"));
      const firstName = formData.get("firstName");
      const lastName = formData.get("lastName");

      setThankYouMessage(
        `Thank you ${firstName} ${lastName}! Our team will connect with you within 24 hours.`
      );
    }
  }, [state.succeeded]); // Run this effect when the submission state changes

  return (
    <div className="booking-page-container">
      {/* Navbar */}
      <Navbar />

      <div className="booking-wrapper">
        {/* Clouds Image */}
        <img
          src={cloud_green}
          alt="Green Cloud"
          className="background-clouds cloud-green"
        />
        <img
          src={cloud_green}
          alt="Green Cloud"
          className="background-clouds cloud-green-2"
        />
        <img
          src={cloud_green}
          alt="Green Cloud"
          className="background-clouds cloud-green-3"
        />

        <img
          src={cloud_purple}
          alt="Purple Cloud"
          className="background-clouds cloud-purple"
        />
        <img
          src={cloud_purple}
          alt="Purple Cloud"
          className="background-clouds cloud-purple-2"
        />
        <img
          src={cloud_purple}
          alt="Purple Cloud"
          className="background-clouds cloud-purple-3"
        />

        <img
          src={cloud_pink}
          alt="Pink Cloud"
          className="background-clouds cloud-pink"
        />
        <img
          src={cloud_pink}
          alt="Pink Cloud"
          className="background-clouds cloud-pink-2"
        />
        <img
          src={cloud_pink}
          alt="Pink Cloud"
          className="background-clouds cloud-pink-3"
        />

        {/* Booking Form or Thank-You Message */}
        {!thankYouMessage ? (
          <div className="booking-form-container">
            <img src={ribbon} alt="Ribbon" className="form-ribbon" />
            <h2 className="center-heading">Book Your Event</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                <div className="form-group-half">
                  <label htmlFor="firstName">First Name:</label>
                  <input type="text" id="firstName" name="firstName" required />
                  <ValidationError
                    prefix="First Name"
                    field="firstName"
                    errors={state.errors}
                  />
                </div>
                <div className="form-group-half">
                  <label htmlFor="lastName">Last Name:</label>
                  <input type="text" id="lastName" name="lastName" required />
                  <ValidationError
                    prefix="Last Name"
                    field="lastName"
                    errors={state.errors}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="form-group-half">
                  <label htmlFor="phone">Phone Number:</label>
                  <input type="tel" id="phone" name="phone" required />
                  <ValidationError
                    prefix="Phone"
                    field="phone"
                    errors={state.errors}
                  />
                </div>
                <div className="form-group-half">
                  <label htmlFor="email">Email:</label>
                  <input type="email" id="email" name="email" required />
                  <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="form-group-half">
                  <label htmlFor="date">Date:</label>
                  <input type="date" id="date" name="date" required />
                  <ValidationError
                    prefix="Date"
                    field="date"
                    errors={state.errors}
                  />
                </div>
                <div className="form-group-half">
                  <label htmlFor="time">Time:</label>
                  <div className="time-input">
                    {/* Hour Dropdown */}
                    <select id="time-hour" name="time-hour" required>
                      {[...Array(12).keys()].map((hour) => {
                        const hourValue = hour + 1;
                        return (
                          <option key={`hour-${hourValue}`} value={hourValue}>
                            {hourValue < 10 ? `0${hourValue}` : hourValue}
                          </option>
                        );
                      })}
                    </select>

                    {/* Colon Separator */}
                    <span className="time-separator">:</span>

                    {/* Minute Dropdown */}
                    <select id="time-minute" name="time-minute" required>
                      <option value="00">00</option>
                      <option value="30">30</option>
                    </select>

                    {/* AM/PM Dropdown */}
                    <select id="time-ampm" name="time-ampm" required>
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                  <ValidationError
                    prefix="Time"
                    field="time"
                    errors={state.errors}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="kids">Number of Kids:</label>
                <input type="number" id="kids" name="kids" required />
                <ValidationError
                  prefix="Kids"
                  field="kids"
                  errors={state.errors}
                />
              </div>
              <div className="form-group">
                <label htmlFor="guests">Number of Guests:</label>
                <input type="number" id="guests" name="guests" required />
                <ValidationError
                  prefix="Guests"
                  field="guests"
                  errors={state.errors}
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" required></textarea>
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
              </div>
              <button type="submit" disabled={state.submitting}>
                Submit Booking
              </button>
            </form>
          </div>
        ) : (
          <div className="thank-you-message">
            <h2>{thankYouMessage}</h2>
            <p>We look forward to making your event special!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingForm;
